import { EstimateDownloadRawAdapter } from '../../../adapter';

export default async function EstimateDownloadRaw(Key) {
    async function execute(Key) {
        const response = await EstimateDownloadRawAdapter(
            'https://vtaq6p2tr8.execute-api.ap-southeast-1.amazonaws.com/api/claims/estimation-module/estimate/download',
            Key
        );

        const byteCharacters = atob(response);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });

        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = Key;
        link.click();

        URL.revokeObjectURL(link.href);
    }

    try {
        return execute(Key);
    } catch (error) {
        console.error('Error download draft estimate handler', error);
        throw new Error('Estimate draft download failed');
    }
}
