import React from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from '../../adapter';
import { HeaderComponent } from '../../presentation';

export default function PrivateLayout() {
    const location = useLocation();
    const { user } = AuthContext();

    if (!user && location.pathname !== '/unauthorized') {
        return <Navigate to="/unauthorized" replace />;
    }
    return (
        <div>
            <HeaderComponent />
            <Outlet />
        </div>
    );
}
