import { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext({});

export const useAuthContext = () => useContext(AuthContext);

export const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState(() => {
        const savedUser = localStorage.getItem('user');
        return savedUser ? JSON.parse(savedUser) : null;
    });

    useEffect(() => {
        const handleStorageChange = () => {
            try {
                const savedUser = localStorage.getItem('user');
                setUser(savedUser ? JSON.parse(savedUser) ?? undefined : null);
            } catch (error) {
                setUser(null);
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [user]);

    const updateUser = (newUser) => {
        localStorage.setItem('user', JSON.stringify(newUser) ?? undefined);
        setUser(newUser);
    };

    const AuthStore = {
        user,
        updateUser
    };

    return <AuthContext.Provider value={AuthStore}>{children}</AuthContext.Provider>;
};
