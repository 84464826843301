import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { AutoSuggestComponent, ClaimsTable } from '../components';

import { InsuredsGetAllService, InsuredsLookupService, UploadDraftEstimateService } from '../../domain';

export default function Dashboard() {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [insureds, setInsureds] = useState([]);

    const fileInputRef = useRef(null);

    useEffect(() => {
        handleInsuredsGet();
    }, []);

    const handleNavigateToEstimations = (id) => {
        navigate('/app/estimations/' + id);
    };

    const handleFileChange = async (event) => {
        if (event.target.files[0]) await handleUpload(event.target.files[0]);
    };

    const handleUpload = async (file) => {
        try {
            const _upload = new UploadDraftEstimateService(file);
            const uploadResult = await _upload.execute();
            const { draftId, insuredId } = uploadResult.data;
            navigate(`/app/forms/estimation/${insuredId}/${draftId}`);
        } catch (error) {
            throw new Error(`Error on upload: ${error}`);
        }
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    async function handleInsuredsGet() {
        try {
            const _insuredsClass = new InsuredsGetAllService();
            const insuredsResult = await _insuredsClass.execute();
            setInsureds(() => insuredsResult.insureds ?? []);
        } catch (error) {
            throw new Error(`Error on insureds get: ${error}`);
        }
    }

    async function handleLookup(search) {
        try {
            const insuredsLookupService = new InsuredsLookupService();
            const result = await insuredsLookupService.execute(search);
            const insureds = result.insureds ?? [];

            return insureds.map((insured) => ({ ...insured, label: insured.insuredGroup }));
        } catch (error) {
            throw new Error(`Error on insureds lookup: ${error}`);
        }
    }

    function handleSearchClicked(args) {
        handleNavigateToEstimations(args.insuredId);
    }

    return (
        <div>
            <main id="main" className="main">
                <div className="pagetitle">
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item active">Dashboard</li>
                        </ol>
                    </nav>
                </div>

                <section className="section dashboard">
                    <div className="col-12">
                        <div className="card recent-sales overflow-auto">
                            <div className="card-body">
                                <h5 className="card-title">
                                    Insureds <span></span>
                                </h5>

                                <div className="row justify-content-between py-2">
                                    <div class="col-2">
                                        <AutoSuggestComponent
                                            id="search-custom"
                                            label="Search"
                                            value=""
                                            searchApi={handleLookup}
                                            handleSuggestionClick={handleSearchClicked}
                                        />
                                    </div>

                                    <div className="col-auto">
                                        <button
                                            type="button"
                                            class="btn btn-primary"
                                            onClick={handleButtonClick}
                                            disabled={loading}
                                        >
                                            <i class="bi bi-cloud-upload px-2"></i>{' '}
                                            {loading ? 'uploading ...' : 'Upload Draft Estimate'}
                                        </button>
                                    </div>
                                </div>

                                <ClaimsTable data={insureds} navigate={handleNavigateToEstimations} />
                            </div>
                        </div>
                    </div>
                </section>

                <input type="file" ref={fileInputRef} onChange={handleFileChange} style={{ display: 'none' }} />
            </main>
        </div>
    );
}
