import { EstimateGetUseCase } from '../../../application';

export default class EstimateGetService {
    constructor() {}

    async execute(draftId) {
        const request = await EstimateGetUseCase(draftId);

        return request;
    }
}
