import { UploadDraftEstimateUseCase } from '../../../application';

export default class UploadDraftEstimateService {
    constructor(file) {
        this.file = file;
    }

    async execute() {
        const request = await UploadDraftEstimateUseCase(this.file);

        return request;
    }
}
