import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AutoSuggestComponent, EstimationsTable } from '../components';

import { EstimatesGetAllService, EstimatesLookupService, InsuredGetService } from '../../domain';

export default function Estimations() {
    const navigate = useNavigate();
    const { insuredId } = useParams();

    const [estimates, setEstimates] = useState([]);
    const [insured, setInsured] = useState({});

    useEffect(() => {
        handleEstimatesGet(parseInt(insuredId) ?? null);
    }, []);

    const handleNavigateToEstimations = (id) => {
        navigate('/app/forms/estimation/' + insuredId + '/' + id);
    };

    async function handleEstimatesGet(insuredId) {
        try {
            const _estimatesClass = new EstimatesGetAllService();
            const estimatesResult = await _estimatesClass.execute({ insuredId });
            setEstimates(() => estimatesResult.estimates ?? []);

            const _insuredClass = new InsuredGetService();
            const insuredResult = await _insuredClass.execute({ insuredId });
            setInsured(() => ({ ...insuredResult.insured }));
        } catch (error) {
            throw new Error(`Error on estimates get: ${error}`);
        }
    }

    async function handleLookup(search) {
        try {
            const estimatesLookupService = new EstimatesLookupService();
            const result = await estimatesLookupService.execute(parseInt(insuredId ?? 0), search);
            const estimates = result.estimates ?? [];

            return estimates.map((estimate) => ({ ...estimate, label: estimate.filename }));
        } catch (error) {
            throw new Error(`Error on estimates lookup: ${error}`);
        }
    }

    function handleSearchClicked(data) {
        navigate('/app/forms/estimation/' + data.insuredId + '/' + data.draftId);
    }

    return (
        <div>
            <main id="main" className="main">
                <div className="pagetitle">
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <a onClick={() => navigate('/app')} style={{ cursor: 'pointer' }}>
                                    Dashboard
                                </a>
                            </li>
                            <li className="breadcrumb-item active" style={{ userSelect: 'none' }}>
                                {insured.insured ?? ''}
                            </li>
                        </ol>
                    </nav>
                </div>

                <section className="section dashboard">
                    <div className="col-12">
                        <div className="card recent-sales overflow-auto">
                            <div className="card-body">
                                <h5 className="card-title">
                                    Estimations <span></span>
                                </h5>

                                <div className="row justify-content-between py-2">
                                    <div class="col-2">
                                        <AutoSuggestComponent
                                            id="search-custom"
                                            label="Search"
                                            value=""
                                            searchApi={handleLookup}
                                            handleSuggestionClick={handleSearchClicked}
                                        />
                                    </div>
                                </div>

                                <EstimationsTable data={estimates} navigate={handleNavigateToEstimations} />
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    );
}
