import { CoreRequest } from '../../../infrastructure';

export default async function EstimateUpdateAdapter(requestUrl, content = {}) {
    const execute = async (requestUrl, body = JSON.stringify({})) => {
        const req = {
            requestUrl,
            body
        };
        const response = await CoreRequest(req);

        return response.json();
    };

    try {
        return execute(requestUrl, content);
    } catch (error) {
        console.error('Error estimate-update handler', error);
        throw new Error(`estimate-update failed: ${error}`);
    }
}
