export default async function ReadAsBase64(file) {
    try {
        if (!file) throw new Error('FILE IS REQUIRED');

        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result.split(',')[1]);
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    } catch (error) {
        throw new Error(error);
    }
}
