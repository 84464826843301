import moment from 'moment';

export default function EstimationsTable({ data = [], navigate = () => {} }) {
    return (
        <table className="table table-borderless datatable">
            <thead className="table-dark">
                <tr>
                    <th scope="col">File Name</th>
                    <th scope="col">Date Created</th>
                    {/* <th scope="col">Actions</th> */}
                </tr>
            </thead>
            <tbody>
                {data.map(({ filename, draftId, dateCreated }) => (
                    <tr>
                        <th>
                            <p
                                class="link-primary"
                                style={{ cursor: 'pointer', width: 'fit-content' }}
                                onClick={() => navigate(draftId)}
                            >
                                {filename}
                            </p>
                        </th>
                        <td>
                            <p style={{ userSelect: 'none' }} onClick={() => navigate('123')}>
                                {moment(new Date(dateCreated)).format('ll')}
                            </p>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
