import { useState, useEffect } from 'react';

import { DebounceUtil } from '../../../utils';

export default function Input(props) {
    const { id, error = undefined, label, value, handleOnChange = () => {}, ...others } = props;

    const [data, setData] = useState('');
    const debounceRef = DebounceUtil(data, 500);

    useEffect(() => {
        if (value === data) return;

        setData(() => value);
    }, [value]);

    useEffect(() => {
        handleOnChange(debounceRef, id);
    }, [debounceRef]);

    function handleChanges(change) {
        setData(() => change.target.value);
    }

    return (
        <div class="form-floating">
            <input
                type="text"
                class="form-control"
                id={id}
                required
                value={data}
                onChange={handleChanges}
                {...others}
            />
            <label for={id}>{label}</label>
            {error && (
                <div class="text-danger px-2">
                    <small>{error}</small>
                </div>
            )}
        </div>
    );
}
