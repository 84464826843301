import { EstimatesLookupUseCase } from '../../../application';

export default class EstimatesLookupService {
    constructor() {}

    async execute(insuredId, search) {
        const request = await EstimatesLookupUseCase({ insuredId, search });

        return request;
    }
}
