import { UploadDraftAdapter } from '../../../adapter';
import { ReadAsBase64Util } from '../../../utils';

export default async function HandleUploadDraftEstimate(file) {
    // validate data
    if (!file) throw 'File is required';

    // prepare data
    const requestUrl =
        'https://vtaq6p2tr8.execute-api.ap-southeast-1.amazonaws.com/api/claims/estimation-module/uploads/draft';
    const fileName = file.name;
    const fileContent = await ReadAsBase64Util(file);

    // handle request
    const result = await UploadDraftAdapter(requestUrl, fileName, { fileContent });

    return result;
}
