export default function ConvertSqlToJsDate(date) {
    // Create a Date object from the input string
    const _date = new Date(date);

    const yyyy = _date.getUTCFullYear();
    const mm = ('0' + (_date.getUTCMonth() + 1)).slice(-2);
    const dd = ('0' + _date.getUTCDate()).slice(-2);

    const outputDate = yyyy + '-' + mm + '-' + dd;

    // Return the reformatted date string
    return outputDate;
}
