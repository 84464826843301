import { InsuredGetAdapter } from '../../../adapter';

export default async function InsuredGet(insuredId) {
    // prepare data
    const requestUrl =
        'https://vtaq6p2tr8.execute-api.ap-southeast-1.amazonaws.com/api/claims/estimation-module/insured/detail';

    // handle request
    const result = await InsuredGetAdapter(requestUrl, JSON.stringify(insuredId));

    return result;
}
