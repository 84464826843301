import React from 'react';
import { createHashRouter, Navigate, RouterProvider } from 'react-router-dom';
import { PrivateLayout, AuthLayout } from '../layouts';
import { LoginView, DashboardView, NotFoundView, EstimationsView, EstimationView } from '../../presentation';
import { AuthContext } from '../../adapter';

const router = createHashRouter([
    {
        index: true,
        element: <Navigate to="/app" replace />
    },
    {
        path: '/app',
        element: <PrivateLayout />,
        children: [
            {
                index: true,
                element: <Navigate to="/app/dashboard" replace />
            },
            {
                path: 'dashboard',
                element: <DashboardView />
            },
            {
                path: 'estimations/:insuredId',
                element: <EstimationsView />
            },
            {
                path: 'forms/estimation/:insuredId/:draftId',
                element: <EstimationView />
            },
            {
                path: '*',
                element: <NotFoundView />
            }
        ]
    },
    {
        path: '/auth',
        element: <AuthLayout />,
        children: [
            {
                index: true,
                element: <Navigate to="/auth/login" replace />
            },
            {
                path: 'login',
                element: <LoginView />
            },
            {
                path: '*',
                element: <NotFoundView />
            }
        ]
    },
    { path: '/unauthorized', element: <Navigate to="/auth" replace /> },
    {
        path: '*',
        element: <NotFoundView />
    }
]);

export default function MainRoutes() {
    const { user } = AuthContext();

    return (
        <RouterProvider router={router}>
            {user ? <Navigate to="/" replace /> : <Navigate to="/auth" replace />}
        </RouterProvider>
    );
}
