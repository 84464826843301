import { useState, useEffect } from 'react';

import { DateComponent, InputComponent, PartsComponent, PopoutComponent, SowComponent, ScrollToTopComponent } from '..';
import { ConvertSqlToJsDateUtil, NumberFormatUtil } from '../../../utils';
import { EstimateDownloadService, EstimateUpdateService, SowLookupService, PartsLookupService } from '../../../domain';

export default function Estimation({ data = {} }) {
    const [form, setForm] = useState({});
    const [toast, setToast] = useState();

    useEffect(() => {
        let { claimDate } = data;

        if (claimDate) claimDate = ConvertSqlToJsDateUtil(claimDate);

        setForm(() => ({ ...data, claimDate }));
    }, [data]);

    function handleEstimationDownload() {
        try {
            const downloadService = new EstimateDownloadService(form);
            downloadService.execute();
        } catch (error) {
            throw new Error(`Error on estimates get: ${error}`);
        }
    }

    async function handleDraftEstimationDownload() {
        try {
            const downloadService = new EstimateDownloadService();
            downloadService.getRawFile(form.filename);
        } catch (error) {
            throw new Error(`Error on estimates get: ${error}`);
        }
    }

    function handleFormChanges(data, target) {
        let claimTotals = {};

        if (target === 'claimLaborRate') {
            claimTotals = handleClaimEstimate(form?.claimScopeOfWork ?? [], form?.claimParts ?? [], data);
        }

        setForm((p) => ({ ...p, [target]: data, ...claimTotals }));
    }

    function handleSowChanges(data, id) {
        if (!id) return;

        const claimScopeOfWork = form?.claimScopeOfWork?.slice() ?? [];
        claimScopeOfWork[id] = Object.assign({}, data);
        const claimTotals = handleClaimEstimate(
            claimScopeOfWork ?? [],
            form?.claimParts ?? [],
            form.claimLaborRate ?? 0
        );

        setForm((p) => ({ ...p, claimScopeOfWork, ...claimTotals }));
    }

    function handlePartsChanges(data, id) {
        if (!id) return;

        const claimParts = form?.claimParts?.slice() ?? [];
        claimParts[id] = Object.assign({}, data);
        const claimTotals = handleClaimEstimate(
            form?.claimScopeOfWork ?? [],
            claimParts ?? [],
            form.claimLaborRate ?? 0
        );

        setForm((p) => ({ ...p, claimParts, ...claimTotals }));
    }

    async function handleDraftUpdate(e) {
        e.preventDefault();
        e.stopPropagation();
        try {
            // send to backend
            const estimateUpdateService = new EstimateUpdateService();
            await estimateUpdateService.execute(form);
            setToast(() => ({ title: 'Draft Estimate', message: 'Update Success!', type: 'success' }));
            setTimeout(() => setToast(), 2500);
        } catch (error) {
            // send to backend
            const estimateUpdateService = new EstimateUpdateService();
            await estimateUpdateService.execute(form);
            setToast(() => ({ title: 'Draft Estimate', message: 'Update Failed', type: 'danger' }));
            setTimeout(() => setToast(), 2500);
        }
    }

    function handleClaimEstimate(sow = [], claimParts = [], laborRate = 0) {
        const repairLabor =
            sow.reduce((accumulator, currentValue) => {
                const repair = parseFloat(currentValue.repair) || 0;
                const dar = parseFloat(currentValue.dar) || 0;

                accumulator += repair + dar;

                return accumulator;
            }, 0) * laborRate;

        const paintingLabor =
            sow.reduce((accumulator, currentValue) => {
                const painting = parseFloat(currentValue.painting) || 0;

                accumulator += painting;

                return accumulator;
            }, 0) * laborRate;

        const shopMaterials = (paintingLabor * 0.85 * 1.35) / 1.12;

        const laborWVat = (repairLabor + paintingLabor + shopMaterials) * 1.12;

        const parts = claimParts.reduce((accumulator, currentValue) => {
            const amount = parseFloat(currentValue.amount) || 0;

            accumulator += amount;

            return accumulator;
        }, 0);

        const totalRepairCost = laborWVat + parts;

        const claimEstimate = {
            repairLabor: repairLabor.toFixed(2),
            paintingLabor: paintingLabor.toFixed(2),
            shopMaterials: shopMaterials.toFixed(2),
            laborWVat: laborWVat.toFixed(2),
            parts: parts.toFixed(2),
            totalRepairCost: totalRepairCost.toFixed(2)
        };

        return claimEstimate;
    }

    async function handleSowLookup(search) {
        try {
            const sowLookupService = new SowLookupService();
            const result = await sowLookupService.execute(search);

            return result;
        } catch (error) {
            throw new Error(`Error on sow lookup: ${error}`);
        }
    }

    async function handlePartsLookup(search) {
        try {
            const partsLookupService = new PartsLookupService();
            const result = await partsLookupService.execute(`${form.unit ?? ''} ${search ?? ''}`.trim());

            return result;
        } catch (error) {
            throw new Error(`Error on parts lookup: ${error}`);
        }
    }

    function handleSowRemove(index) {
        const claimScopeOfWork = form?.claimScopeOfWork?.slice() ?? [];
        let claimScopeOfWorkRemove;

        if (claimScopeOfWork[index].sowId) {
            claimScopeOfWorkRemove = form?.claimScopeOfWorkRemove?.slice() ?? [];
            claimScopeOfWorkRemove.push(claimScopeOfWork[index].sowId);
        }

        claimScopeOfWork.splice(index, 1);

        const claimTotals = handleClaimEstimate(
            claimScopeOfWork ?? [],
            form?.claimParts ?? [],
            form.claimLaborRate ?? 0
        );

        setForm((p) => ({
            ...p,
            claimScopeOfWork,
            ...claimTotals,
            ...((claimScopeOfWorkRemove &&
                claimScopeOfWorkRemove.length && {
                    claimScopeOfWorkRemove
                }) ||
                {})
        }));
    }

    function handlePartsRemove(index) {
        const claimParts = form?.claimParts?.slice() ?? [];

        let claimPartsRemove;
        if (claimParts[index].partId) {
            claimPartsRemove = form?.claimPartsRemove?.slice() ?? [];
            claimPartsRemove.push(claimParts[index].partId);
        }

        claimParts.splice(index, 1);

        const claimTotals = handleClaimEstimate(
            form?.claimScopeOfWork ?? [],
            claimParts ?? [],
            form.claimLaborRate ?? 0
        );

        setForm((p) => ({
            ...p,
            claimParts,
            ...claimTotals,
            ...((claimPartsRemove &&
                claimPartsRemove.length && {
                    claimPartsRemove
                }) ||
                {})
        }));
    }

    function handleSowNew() {
        const claimScopeOfWork = form?.claimScopeOfWork?.slice() ?? [];
        claimScopeOfWork.push({
            scopeOfWork: '',
            repairCondition: '',
            repair: '',
            dar: '',
            painting: ''
        });

        setForm((p) => ({ ...p, claimScopeOfWork }));
    }

    function handlePartsNew() {
        const claimParts = form?.claimParts?.slice() ?? [];
        claimParts.push({
            parts: '',
            amount: ''
        });

        setForm((p) => ({ ...p, claimParts }));
    }

    return (
        <>
            <div>
                <section class="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-2">
                    <div>
                        <div class="row justify-content-center">
                            <div class="col-lg-12 col-md-12 d-flex flex-column align-items-center justify-content-center">
                                <div class="card mb-3">
                                    <div class="card-body">
                                        <div class="row d-flex justify-content-between ">
                                            <div class="pt-4 pb-2 col">
                                                <h5 class="card-title text-left pb-0 fs-4">Claim Details</h5>
                                                <p class="text-left small">Claim basic details</p>
                                            </div>

                                            <div class="col-5 pt-4">
                                                <div class="d-flex flex-row justify-content-end">
                                                    <div class="mx-2">
                                                        <button
                                                            type="button"
                                                            class="btn btn-outline-primary px-3"
                                                            onClick={handleDraftEstimationDownload}
                                                        >
                                                            <i class="bi bi-file-earmark-arrow-down-fill px-2"></i>{' '}
                                                            Download Draft File
                                                        </button>
                                                    </div>
                                                    <div class="mx-2">
                                                        <button
                                                            type="button"
                                                            class="btn btn-outline-primary px-3"
                                                            onClick={handleEstimationDownload}
                                                        >
                                                            <i class="bi bi-file-earmark-arrow-down-fill px-2"></i>{' '}
                                                            Download Estimate File
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <form class="row g-3 needs-validation" novalidate>
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-4">
                                                        <InputComponent
                                                            id="insured"
                                                            // error="Insured Name is Required."
                                                            label="Insured"
                                                            value={form.insured}
                                                            handleOnChange={handleFormChanges}
                                                        />
                                                    </div>
                                                    <div class="col-4">
                                                        <InputComponent
                                                            id="inspectedAt"
                                                            // error="Inspection Location is Required."
                                                            label="Inspected At"
                                                            value={form.inspectedAt}
                                                            handleOnChange={handleFormChanges}
                                                        />
                                                    </div>
                                                    <div class="col-4">
                                                        <InputComponent
                                                            id="controlNo"
                                                            // error="Control No. is Required."
                                                            label="Control No."
                                                            value={form.controlNo}
                                                            handleOnChange={handleFormChanges}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-4">
                                                        <InputComponent
                                                            id="unit"
                                                            // error="Insured Name is Required."
                                                            label="Unit"
                                                            value={form.unit}
                                                            handleOnChange={handleFormChanges}
                                                        />
                                                    </div>
                                                    <div class="col-4">
                                                        <InputComponent
                                                            id="motorNo"
                                                            // error="Insured Name is Required."
                                                            label="Motor No."
                                                            value={form.motorNo}
                                                            handleOnChange={handleFormChanges}
                                                        />
                                                    </div>
                                                    <div class="col-4">
                                                        <DateComponent
                                                            id="claimDate"
                                                            // error="Insured Name is Required."
                                                            label="Date"
                                                            value={form.claimDate}
                                                            handleOnChange={handleFormChanges}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-4">
                                                        <InputComponent
                                                            id="plateNo"
                                                            // error="Insured Name is Required."
                                                            label="Plate No."
                                                            value={form.plateNo}
                                                            handleOnChange={handleFormChanges}
                                                        />
                                                    </div>
                                                    <div class="col-4">
                                                        <InputComponent
                                                            id="serialNo"
                                                            // error="Insured Name is Required."
                                                            label="Serial No."
                                                            value={form.serialNo}
                                                            handleOnChange={handleFormChanges}
                                                        />
                                                    </div>
                                                    <div class="col-4">
                                                        <DateComponent
                                                            id="claimTime"
                                                            // error="Insured Name is Required."
                                                            label="Time"
                                                            type="time"
                                                            step="1"
                                                            value={form.claimTime}
                                                            handleOnChange={handleFormChanges}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-3">
                                                        <InputComponent
                                                            id="policyNo"
                                                            // error="Insured Name is Required."
                                                            label="Policy No."
                                                            value={form.policyNo}
                                                            handleOnChange={handleFormChanges}
                                                        />
                                                    </div>
                                                    <div class="col-3">
                                                        <InputComponent
                                                            id="modelCode"
                                                            // error="Insured Name is Required."
                                                            label="Model Code"
                                                            value={form.modelCode}
                                                            handleOnChange={handleFormChanges}
                                                        />
                                                    </div>
                                                    <div class="col-3">
                                                        <InputComponent
                                                            id="claimNo"
                                                            // error="Insured Name is Required."
                                                            label="Claim No."
                                                            value={form.claimNo}
                                                            handleOnChange={handleFormChanges}
                                                        />
                                                    </div>
                                                    <div class="col-3">
                                                        <InputComponent
                                                            id="assignedBy"
                                                            // error="Insured Name is Required."
                                                            label="Assigned By"
                                                            value={form.assignedBy}
                                                            handleOnChange={handleFormChanges}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="py-0">
                                                <div className="row d-flex justify-content-between">
                                                    <div className="col">
                                                        <h5 class="card-title text-left pb-0 fs-4">Scope of Work</h5>
                                                        <p class="text-left small">Claim scope of work line items</p>
                                                    </div>
                                                    <div className="col d-flex justify-content-end align-items-end p-3">
                                                        <button
                                                            type="button"
                                                            class="btn btn-outline-success px-3"
                                                            onClick={handleSowNew}
                                                        >
                                                            <i class="bi bi-plus-circle-fill px-2"></i> Add Scope of
                                                            Work
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-12">
                                                {(form.claimScopeOfWork ?? []).map((data, index) => (
                                                    <SowComponent
                                                        key={`sow-row-${index}`}
                                                        data={data}
                                                        index={index}
                                                        handleComponentChanges={handleSowChanges}
                                                        handleRemoveLineItem={() => handleSowRemove(index)}
                                                        lookupService={handleSowLookup}
                                                    />
                                                ))}
                                            </div>

                                            <div class="py-0">
                                                <div className="row d-flex justify-content-between">
                                                    <div className="col">
                                                        <h5 class="card-title text-left pb-0 fs-4">Parts</h5>
                                                        <p class="text-left small">Claim parts line items</p>
                                                    </div>
                                                    <div className="col d-flex justify-content-end align-items-end p-3">
                                                        <button
                                                            type="button"
                                                            class="btn btn-outline-success px-3"
                                                            onClick={handlePartsNew}
                                                        >
                                                            <i class="bi bi-plus-circle-fill px-2"></i> Add Parts
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-12">
                                                {(form.claimParts ?? []).map((data, index) => (
                                                    <PartsComponent
                                                        key={`parts-row-${index}`}
                                                        data={data}
                                                        index={index}
                                                        handleComponentChanges={handlePartsChanges}
                                                        handleRemoveLineItem={() => handlePartsRemove(index)}
                                                        lookupService={handlePartsLookup}
                                                    />
                                                ))}
                                            </div>

                                            <div class="py-0">
                                                <h5 class="card-title text-left pb-0 fs-4">Summary</h5>
                                                <p class="text-left small">Claim estimation summary</p>
                                            </div>

                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-4">
                                                        <div class="py-0">
                                                            <h6 class="card-title text-left py-0 fs-4">Labor</h6>
                                                        </div>
                                                        <div class="row g-2">
                                                            <div class="col-12">
                                                                <InputComponent
                                                                    id="claimLaborRate"
                                                                    // error="Insured Name is Required."
                                                                    label="Labor Rate"
                                                                    value={form.claimLaborRate}
                                                                    handleOnChange={handleFormChanges}
                                                                />
                                                            </div>
                                                            <div class="col-12">
                                                                <InputComponent
                                                                    id="repairLabor"
                                                                    // error="Insured Name is Required."
                                                                    label="Repair Labor"
                                                                    value={NumberFormatUtil(form.repairLabor)}
                                                                    handleOnChange={handleFormChanges}
                                                                    disabled
                                                                />
                                                            </div>
                                                            <div class="col-12">
                                                                <InputComponent
                                                                    id="paintingLabor"
                                                                    // error="Insured Name is Required."
                                                                    label="Painting Labor"
                                                                    value={NumberFormatUtil(form.paintingLabor)}
                                                                    handleOnChange={handleFormChanges}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="py-0">
                                                            <h6 class="card-title text-left py-0 fs-4">Parts</h6>
                                                        </div>
                                                        <div class="row g-2">
                                                            <div class="col-12">
                                                                <InputComponent
                                                                    id="shopMaterials"
                                                                    // error="Insured Name is Required."
                                                                    label="Shop Materials"
                                                                    value={NumberFormatUtil(form.shopMaterials)}
                                                                    handleOnChange={handleFormChanges}
                                                                    disabled
                                                                />
                                                            </div>
                                                            <div class="col-12">
                                                                <InputComponent
                                                                    id="parts"
                                                                    // error="Insured Name is Required."
                                                                    label="Parts"
                                                                    value={NumberFormatUtil(form.parts)}
                                                                    handleOnChange={handleFormChanges}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="py-0">
                                                            <h6 class="card-title text-left py-0 pb-0 fs-4">
                                                                Breakdown
                                                            </h6>
                                                        </div>
                                                        <div class="row g-2">
                                                            <div class="col-12">
                                                                <InputComponent
                                                                    id="laborWVat"
                                                                    // error="Insured Name is Required."
                                                                    label="Labor (+12% VAT)"
                                                                    value={NumberFormatUtil(form.laborWVat)}
                                                                    handleOnChange={handleFormChanges}
                                                                    disabled
                                                                />
                                                            </div>
                                                            <div class="col-12">
                                                                <InputComponent
                                                                    id="merged-parts-materials-total"
                                                                    // error="Insured Name is Required."
                                                                    label="Totals Parts / Shop Materials"
                                                                    value={NumberFormatUtil(
                                                                        parseFloat(form.parts ?? 0) +
                                                                            parseFloat(form.shopMaterials ?? 0)
                                                                    )}
                                                                    handleOnChange={handleFormChanges}
                                                                    disabled
                                                                />
                                                            </div>
                                                            <div class="col-12">
                                                                <InputComponent
                                                                    id="totalRepairCost"
                                                                    // error="Insured Name is Required."
                                                                    label="Total Repair Cost"
                                                                    value={NumberFormatUtil(form.totalRepairCost)}
                                                                    handleOnChange={handleFormChanges}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="py-0">
                                                <h5 class="card-title text-left pb-0 fs-4">Other Claim Details</h5>
                                                <p class="text-left small">Claims approval details</p>
                                            </div>

                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-4">
                                                        <InputComponent
                                                            id="estimateCode"
                                                            // error="Insured Name is Required."
                                                            label="Estimate Code"
                                                            value={form.estimateCode}
                                                            handleOnChange={handleFormChanges}
                                                        />
                                                    </div>
                                                    <div class="col-4">
                                                        <InputComponent
                                                            id="remarks"
                                                            // error="Insured Name is Required."
                                                            label="Remarks"
                                                            value={form.remarks}
                                                            handleOnChange={handleFormChanges}
                                                        />
                                                    </div>
                                                    <div class="col-4">
                                                        <InputComponent
                                                            id="approvedBy"
                                                            // error="Insured Name is Required."
                                                            label="Approved By / DATE-TIME"
                                                            value={form.approvedBy}
                                                            handleOnChange={handleFormChanges}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-4">
                                                        <InputComponent
                                                            id="inspectedBy"
                                                            // error="Insured Name is Required."
                                                            label="Inspected By"
                                                            value={form.inspectedBy}
                                                            handleOnChange={handleFormChanges}
                                                        />
                                                    </div>
                                                    <div class="col-4">
                                                        <InputComponent
                                                            id="canvassedBy"
                                                            // error="Insured Name is Required."
                                                            label="Canvassed / Approved By"
                                                            value={form.canvassedBy}
                                                            handleOnChange={handleFormChanges}
                                                        />
                                                    </div>
                                                    <div class="col-4">
                                                        <InputComponent
                                                            id="encodedBy"
                                                            // error="Insured Name is Required."
                                                            label="Encoded By / DATE-TIME"
                                                            value={form.encodedBy}
                                                            handleOnChange={handleFormChanges}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-12 d-flex justify-content-end">
                                                <button class="btn btn-success px-5" onClick={handleDraftUpdate}>
                                                    Update
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <ScrollToTopComponent />
            {toast && <PopoutComponent {...toast} />}
        </>
    );
}
