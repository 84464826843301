export default function formatNumber(number = 0) {
    let result = 0;
    if (Number.isInteger(number)) {
        result = (number && number.toString()) || 0;
    } else {
        result =
            (number &&
                parseFloat(number)
                    .toFixed(2)
                    .replace(/\.?0+$/, '')) ||
            0;
    }

    return parseFloat(result) ?? undefined;
}
