import { EstimatesLookupAdapter } from '../../../adapter';

export default async function EstimatesLookup(data) {
    // prepare data
    const requestUrl =
        'https://vtaq6p2tr8.execute-api.ap-southeast-1.amazonaws.com/api/claims/estimation-module/lookup/estimates';

    // handle request
    const result = await EstimatesLookupAdapter(requestUrl, JSON.stringify(data));

    return result;
}
