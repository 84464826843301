import { PartsLookupUseCase } from '../../../application';

export default class PartsLookupService {
    constructor() {}

    async execute(search) {
        const request = await PartsLookupUseCase({ search });

        return request;
    }
}
