import { useState } from 'react';

import { AuthContext } from '../../adapter';

import { AuthService } from '../../domain';

export default function Login() {
    const _auth = new AuthService();
    const { updateUser } = AuthContext();

    const [data, setData] = useState({});

    const handleLogin = async (props) => {
        try {
            await _auth.RequestLogin(props, updateUser);
        } catch (error) {
            console.error('Error', error);
            alert('Invalid Credentials');
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        handleLogin(data);
    };

    return (
        <>
            <main>
                <div className="container">
                    <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 d-flex flex-column align-items-center justify-content-center">
                                    <div className="d-flex justify-content-center py-4">
                                        <p className="logo d-flex align-items-center w-auto">
                                            <span className="d-none d-sm-block text-center">
                                                BPIMS Claims Estimation Module
                                            </span>
                                        </p>
                                    </div>

                                    <div className="card mb-3">
                                        <div className="card-body">
                                            <div className="pt-4 pb-2">
                                                <h5 className="card-title text-center pb-0 fs-4">
                                                    Login to Your Account
                                                </h5>
                                                <p className="text-center small">
                                                    Enter your username & password to login
                                                </p>
                                            </div>

                                            <form
                                                className="row g-3 needs-validation"
                                                noValidate
                                                onSubmit={handleSubmit}
                                            >
                                                <div className="col-12">
                                                    <label for="yourUsername" className="form-label">
                                                        Username
                                                    </label>
                                                    <div className="input-group has-validation">
                                                        <span className="input-group-text" id="inputGroupPrepend">
                                                            @
                                                        </span>
                                                        <input
                                                            type="text"
                                                            name="username"
                                                            className="form-control"
                                                            id="yourUsername"
                                                            required
                                                            onChange={(e) =>
                                                                setData((p) => ({ ...p, username: e.target.value }))
                                                            }
                                                        />
                                                        <div className="invalid-feedback">
                                                            Please enter your username.
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12">
                                                    <label for="yourPassword" className="form-label">
                                                        Password
                                                    </label>
                                                    <input
                                                        type="password"
                                                        name="password"
                                                        className="form-control"
                                                        id="yourPassword"
                                                        required
                                                        onChange={(e) =>
                                                            setData((p) => ({ ...p, password: e.target.value }))
                                                        }
                                                    />
                                                    <div className="invalid-feedback">Please enter your password!</div>
                                                </div>

                                                <div className="col-12">
                                                    <button className="btn btn-primary w-100" type="submit">
                                                        Login
                                                    </button>
                                                </div>
                                                {/* <div className="col-12">
                                                    <p className="small mb-0">
                                                        Don't have account?{' '}
                                                        <a href="pages-register.html">Create an account</a>
                                                    </p>
                                                </div> */}
                                            </form>
                                        </div>
                                    </div>

                                    <div className="credits"></div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
        </>
    );
}
