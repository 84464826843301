import { useState, useEffect } from 'react';
import { isEqual } from 'lodash';

import { DebounceUtil } from '../../../utils';

import { AutoSuggestComponent, InputComponent, RemoveRowComponent, SelectComponent } from '..';

export default function SowComponent(props) {
    const {
        index,
        data = {},
        handleComponentChanges = () => {},
        lookupService = () => {},
        handleRemoveLineItem = () => {}
    } = props;

    const [form, setForm] = useState({});
    useEffect(() => {
        if (isEqual(data, form)) return;

        setForm(() => ({ ...data }));
    }, [data]);

    const debounceRef = DebounceUtil(form, 500);

    useEffect(() => {
        if (!Object.keys(form ?? {}).length) return;
        if (isEqual(data, form)) return;

        handleComponentChanges(form, index);
    }, [debounceRef]);

    function handleFormChanges(data, id) {
        setForm((p) => ({ ...p, [id]: data }));
    }

    function handleSuggestionClick(data, id) {
        const { name: scopeOfWork, paint: painting, repair, replace_val: dar } = data;

        setForm((p) => ({ ...p, scopeOfWork, repair, dar, painting }));
    }

    return (
        <div class="row mb-3" style={{ position: 'relative' }}>
            <RemoveRowComponent handleRowRemove={handleRemoveLineItem} />
            <div class="col-3">
                <AutoSuggestComponent
                    id="scopeOfWork"
                    // error="Insured Name is Required."
                    label="Scope of Work"
                    value={form.scopeOfWork}
                    handleOnChange={handleFormChanges}
                    searchApi={lookupService}
                    handleSuggestionClick={handleSuggestionClick}
                />
            </div>
            <div class="col-3">
                <SelectComponent
                    id="repairCondition"
                    // error="Insured Name is Required."
                    label="Repair Condition"
                    value={form.repairCondition}
                    options={['Replace', 'Repair', 'Polish', 'Align']}
                    handleOnChange={handleFormChanges}
                />
            </div>
            <div class="col-2">
                <InputComponent
                    id="repair"
                    // error="Insured Name is Required."
                    label="Repair"
                    value={form.repair}
                    type="number"
                    handleOnChange={handleFormChanges}
                />
            </div>
            <div class="col-2">
                <InputComponent
                    id="dar"
                    // error="Insured Name is Required."
                    label="D&A / Replace"
                    value={form.dar}
                    type="number"
                    handleOnChange={handleFormChanges}
                />
            </div>
            <div class="col-2">
                <InputComponent
                    id="painting"
                    // error="Insured Name is Required."
                    label="Painting"
                    value={form.painting}
                    type="number"
                    handleOnChange={handleFormChanges}
                />
            </div>
        </div>
    );
}
