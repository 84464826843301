export default function Popout(props) {
    return (
        <div className="toast-container position-fixed top-0 end-0 p-3">
            <div id="liveToast" className="toast show" role="alert" aria-live="assertive" aria-atomic="true">
                <div className="toast-header">
                    <strong className={`me-auto text-${props.type ?? 'success'}`}>{props.title}</strong>
                </div>
                <div className="toast-body">{props.message}</div>
            </div>
        </div>
    );
}
