import { EstimateGetAllAdapter } from '../../../adapter';

export default async function EstimatesGetAll(insuredId) {
    // prepare data
    const requestUrl =
        'https://vtaq6p2tr8.execute-api.ap-southeast-1.amazonaws.com/api/claims/estimation-module/estimate';

    // handle request
    const result = await EstimateGetAllAdapter(requestUrl, JSON.stringify(insuredId));

    return result;
}
