import { useState, useEffect } from 'react';

import { DebounceUtil } from '../../../utils';

export default function AutoSuggest(props) {
    const {
        id,
        error = undefined,
        label,
        value,
        handleOnChange = () => {},
        searchApi = async () => {
            return [];
        },
        handleSuggestionClick = () => {},
        ...others
    } = props;

    const [data, setData] = useState('');
    const [focused, setFocused] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const debounceRef = DebounceUtil(data, 500);

    useEffect(() => {
        return () => {
            setFocused(false);
        };
    }, []);

    useEffect(() => {
        if (value === data) return;

        setData(value);
    }, [value]);

    useEffect(() => {
        handleOnChange(debounceRef, id);
        handleSearch(debounceRef);
    }, [debounceRef]);

    function handleChanges(change) {
        setData(change.target.value);
    }

    async function handleSearch(search) {
        setSuggestions([]);

        const suggestions = (await searchApi(search)) ?? [];
        setSuggestions(suggestions);
    }

    function handleSuggestionSelect(data) {
        handleSuggestionClick(data, id);
        setFocused(false);
    }

    function handleSuggestionMouseDown(event) {
        event.preventDefault();
    }

    return (
        <div className="form-floating" style={{ position: 'relative' }}>
            <input
                type="text"
                className="form-control"
                id={id}
                required
                value={data}
                onChange={handleChanges}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                {...others}
            />
            <label htmlFor={id}>{label}</label>
            {error && (
                <div className="text-danger px-2">
                    <small>{error}</small>
                </div>
            )}

            {suggestions.length > 0 && focused && (
                <div
                    style={{
                        position: 'absolute',
                        width: '100%',
                        display: 'flex',
                        top: '115%',
                        background: '#fff',
                        borderRadius: '4px',
                        zIndex: 200,
                        flexDirection: 'column',
                        boxShadow: '0px 0px 8px 0px rgba(0,0,0,0.6)',
                        maxHeight: '200px',
                        overflowY: 'auto'
                    }}
                >
                    {suggestions.map((data, index) => (
                        <div
                            key={`suggestion-box-${index}-${data.label}`}
                            onMouseDown={handleSuggestionMouseDown} // Prevent blur
                            onClick={() => handleSuggestionSelect(data)}
                            style={{
                                padding: '5px 10px',
                                ...(index + 1 !== suggestions.length && {
                                    borderBottom: 'solid 1px rgba(0, 0, 0, 0.1)'
                                }),
                                cursor: 'pointer',
                                zIndex: 201
                            }}
                        >
                            {data.label}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}
