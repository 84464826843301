import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { EstimationForm } from '../components';

import { EstimateGetService, InsuredGetService } from '../../domain';

export default function Dashboard() {
    const navigate = useNavigate();
    const { draftId, insuredId } = useParams();
    const [insured, setInsured] = useState({});
    const [estimate, setEstimate] = useState({});

    useEffect(() => {
        handleEstimateGet(parseInt(draftId) ?? null);
        handleInsuredGet(parseInt(insuredId) ?? null);
    }, []);

    async function handleEstimateGet(draftId) {
        try {
            const _estimatesClass = new EstimateGetService();
            const estimateResult = await _estimatesClass.execute({ draftId });
            const {
                claimDetails = {},
                claimLaborRate = 0,
                claimEstimate = {},
                claimParts = [],
                claimScopeOfWork = []
            } = estimateResult;

            setEstimate(() => ({
                ...claimDetails,
                claimLaborRate,
                ...claimEstimate,
                claimParts,
                claimScopeOfWork
            }));
        } catch (error) {
            throw new Error(`Error on estimate get: ${error}`);
        }
    }

    async function handleInsuredGet(insuredId) {
        try {
            const _insuredClass = new InsuredGetService();
            const insuredResult = await _insuredClass.execute({ insuredId });
            setInsured(() => ({ ...insuredResult.insured }));
        } catch (error) {
            throw new Error(`Error on estimates get: ${error}`);
        }
    }

    function handleNavigateToInsured(insuredId) {
        navigate('/app/estimations/' + insuredId);
    }

    return (
        <div>
            <main id="main" className="main">
                <div className="pagetitle">
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <a onClick={() => navigate('/app')} style={{ cursor: 'pointer' }}>
                                    Dashboard
                                </a>
                            </li>
                            <li className="breadcrumb-item" style={{ userSelect: 'none' }}>
                                <a onClick={() => handleNavigateToInsured(insuredId)} style={{ cursor: 'pointer' }}>
                                    {insured.insured ?? ''}
                                </a>
                            </li>
                            <li className="breadcrumb-item active" style={{ userSelect: 'none' }}>
                                {estimate.filename}
                            </li>
                        </ol>
                    </nav>
                </div>

                <section className="section">
                    <EstimationForm data={estimate} />
                </section>
            </main>
        </div>
    );
}
