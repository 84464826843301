import { EstimateGetAdapter } from '../../../adapter';

export default async function EstimateGet(draftId) {
    // prepare data
    const requestUrl =
        'https://vtaq6p2tr8.execute-api.ap-southeast-1.amazonaws.com/api/claims/estimation-module/estimate/detail';

    // handle request
    const result = await EstimateGetAdapter(requestUrl, JSON.stringify(draftId));

    return result;
}
