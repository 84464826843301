import { PartsLookupAdapter } from '../../../adapter';

export default async function PartsLookup(search) {
    // prepare data
    const requestUrl =
        'https://vtaq6p2tr8.execute-api.ap-southeast-1.amazonaws.com/api/claims/estimation-module/lookup/parts';

    // handle request
    const result = await PartsLookupAdapter(requestUrl, JSON.stringify(search));

    const lookup = result.map(({ parts, price, ...others }) => ({
        label: [parts, price].join(' - '),
        parts,
        price,
        ...others
    }));

    return lookup;
}
