import { useState, useEffect } from 'react';

export default function HandleDebounce(input, delay) {
    const [debounce, setDebounce] = useState(input);

    useEffect(() => {
        if (typeof input === 'undefined') return;
        const ref = setTimeout(() => {
            setDebounce(input);
        }, delay);

        return () => {
            clearTimeout(ref);
        };
    }, [input, delay]);

    return debounce;
}
