export default function ClaimsTable({ data = [], navigate = () => {} }) {
    return (
        <table className="table table-borderless datatable">
            <thead className="table-dark">
                <tr>
                    <th scope="col">Insured</th>
                    {/* <th scope="col">Actions</th> */}
                </tr>
            </thead>
            <tbody>
                {data.map(({ insuredId, insuredGroup, drafts }) => (
                    <tr>
                        <td>
                            <p
                                class="link-primary my-1"
                                style={{ cursor: 'pointer', width: 'fit-content' }}
                                onClick={() => navigate(insuredId)}
                            >
                                {insuredGroup} {drafts && <span class="badge text-bg-primary mx-3">{drafts}</span>}
                            </p>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
