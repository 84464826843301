import { useState, useEffect } from 'react';

import { DebounceUtil } from '../../../utils';

export default function Select(props) {
    const { id, error = undefined, label, value, options = [], handleOnChange = () => {}, ...others } = props;

    const [data, setData] = useState('');
    const debounceRef = DebounceUtil(data, 500);

    useEffect(() => {
        if (value === data) return;

        setData(() => value);
    }, [value]);

    useEffect(() => {
        handleOnChange(debounceRef, id);
    }, [debounceRef]);

    function handleChanges(change) {
        setData(() => change.target.value);
    }

    return (
        <div class="form-floating">
            <select class="form-select" id={id} aria-label="State" value={data} onChange={handleChanges} {...others}>
                <option default value={null}>
                    Select {label}
                </option>
                {options.map((value) => (
                    <option value={value}>{value}</option>
                ))}
            </select>
            <label for={id}>{label}</label>
        </div>
    );
}
