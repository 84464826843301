import moment from 'moment';

import { EstimateDownloadAdapter } from '../../../adapter';

export default function ProcessEstimateDownload(data) {
    const { claimParts: parts = [], claimScopeOfWork: sow = [], claimLaborRate = 0, ...others } = data;

    const claimDetails = {
        insured: others.insured,
        'inspected at': others.inspectedAt,
        'control no.': others.controlNo,
        unit: others.unit,
        'motor no.': others.motorNo,
        date: others.claimDate ? moment(others.claimDate).format('DD/MM/YYYY') : '',
        'plate no.': others.plateNo,
        'serial no.': others.serialNo,
        time: others.claimTime,
        'policy no.': others.policyNo,
        'model code': others.modelCode,
        'claim no.': others.claimNo,
        'assigned by': others.assignedBy,
        'estimate code': '',
        remarks: '',
        'approved by': '',
        'inspected by': '',
        'canvassed by': '',
        'encoded by': ''
    };

    const claimScopeOfWork = sow.map(({ scopeOfWork, repairCondition, repair, dar, painting }) => ({
        'scope of work': scopeOfWork,
        'repair condition': repairCondition,
        repair: repair,
        'd & a/replace': dar,
        painting: painting,
        'repair labor': claimLaborRate * (repair + dar),
        'painting labor': claimLaborRate * painting
    }));

    const claimParts = parts.map(({ parts, amount }) => ({
        'parts name': parts,
        amount
    }));

    const claimEstimate = {
        'repair labor': others.repairLabor,
        'painting labor': others.paintingLabor,
        'shop materials': others.shopMaterials,
        'labor +VAT': others.laborWVat,
        parts: others.parts,
        'total repair cost': others.totalRepairCost
    };

    return EstimateDownloadAdapter(claimDetails, claimEstimate, claimScopeOfWork, claimParts);
}
