import { EstimateUpdateAdapter } from '../../../adapter';

export default async function EstimateUpdate(data) {
    // prepare data
    const requestUrl =
        'https://vtaq6p2tr8.execute-api.ap-southeast-1.amazonaws.com/api/claims/estimation-module/estimate/save';

    // handle request
    const result = await EstimateUpdateAdapter(requestUrl, JSON.stringify(data));

    return result;
}
