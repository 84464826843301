import { InsuredGetUseCase } from '../../../application';

export default class InsuredGetService {
    constructor() {}

    async execute(insuredId) {
        const request = await InsuredGetUseCase(insuredId);

        return request;
    }
}
