export default function ScrollToTopComponent() {
    const handleScrollToTop = function () {
        window.scrollTo(0, 0);
    };
    return (
        <div
            style={{
                width: 'fit-content',
                position: 'fixed',
                bottom: '10px',
                right: '10px',
                cursor: 'pointer'
            }}
            onClick={handleScrollToTop}
        >
            <i class="bi bi-arrow-up-circle-fill" style={{ fontSize: '30px', color: '#012970' }}></i>
        </div>
    );
}
