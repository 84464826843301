import { InsuredsGetAllAdapter } from '../../../adapter';

export default async function InsuredsGetAll() {
    // prepare data
    const requestUrl =
        'https://vtaq6p2tr8.execute-api.ap-southeast-1.amazonaws.com/api/claims/estimation-module/insured';

    // handle request
    const result = await InsuredsGetAllAdapter(requestUrl);

    return result;
}
