import { useState, useEffect } from 'react';
import { isEqual } from 'lodash';

import { DebounceUtil } from '../../../utils';

import { InputComponent, RemoveRowComponent, AutoSuggestComponent } from '..';

export default function PartsComponent(props) {
    const {
        index,
        data = {},
        handleComponentChanges = () => {},
        lookupService = () => {},
        handleRemoveLineItem = () => {}
    } = props;

    const [form, setForm] = useState({});
    useEffect(() => {
        if (isEqual(data, form)) return;

        setForm(() => ({ ...data }));
    }, [data]);

    const debounceRef = DebounceUtil(form, 500);

    useEffect(() => {
        if (!Object.keys(form ?? {}).length) return;
        if (isEqual(data, form)) return;

        handleComponentChanges(form, index);
    }, [debounceRef]);

    function handleFormChanges(data, id) {
        setForm((p) => ({ ...p, [id]: data }));
    }

    function handleSuggestionClick(data) {
        const { parts, price: amount } = data;

        setForm((p) => ({ ...p, parts, amount }));
    }

    return (
        <div class="row mb-3" style={{ position: 'relative' }}>
            <RemoveRowComponent handleRowRemove={handleRemoveLineItem} />
            <div class="col-3">
                <AutoSuggestComponent
                    id="parts"
                    // error="Insured Name is Required."
                    label="Part Name"
                    value={form.parts}
                    handleOnChange={handleFormChanges}
                    searchApi={lookupService}
                    handleSuggestionClick={handleSuggestionClick}
                />
            </div>
            <div class="col-3">
                <InputComponent
                    id="amount"
                    // error="Insured Name is Required."
                    label="Amount"
                    value={form.amount}
                    type="number"
                    handleOnChange={handleFormChanges}
                />
            </div>
        </div>
    );
}
