import { LoginUseCase, UserAuthenticationUseCase } from '../../../application';

export default class AuthService {
    constructor() {}

    async RequestLogin(User, AuthenticateUserStore) {
        const login = await LoginUseCase(User.username, User.password);
        await UserAuthenticationUseCase(login, AuthenticateUserStore);

        return;
    }
}
