import { LoginAdapter } from '../../../adapter';
import { UserValidator } from '../../../domain';

export default async function handleLogin(username, password) {
    // sanitize data
    const _username = username.trim();
    const _password = password.trim();

    // validate data
    UserValidator(_username, _password);

    // handle request
    const result = await LoginAdapter(_username, _password);

    return result;
}
