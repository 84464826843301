import { SowLookupUseCase } from '../../../application';

export default class SowLookupService {
    constructor() {}

    async execute(search) {
        const request = await SowLookupUseCase({ search });

        return request;
    }
}
