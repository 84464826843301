/**
 * Handles User Requests to Core Applications
 * @param {object} Config requestUrl, headers, body
 *
 * @return {{ response: { data: object }}} Promise { response }
 */
export default async function CoreRequest(
    config = {
        headers: {},
        requestUrl: '',
        body: {},
        responseType: 'json'
    }
) {
    try {
        const url = window.location.href;
        const [, stage, , flag] = url.split('.');
        const response = await fetch(
            // `${flag ? 'https://partners-api' : 'https://fes'}.${flag ? `${stage}.` : ''}bpimsapps.com${
            //     flag ? '' : '/partners'
            // }/${config.requestUrl}`,
            config.requestUrl,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    ...config.headers
                },
                body: config.body
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        if (config.responseType === 'blob') {
            console.log(response, 'not blobbed');
            return response.blob();
        }

        return response;
    } catch (error) {
        console.error('CoreRequest api error', error);
        throw error;
    }
}
