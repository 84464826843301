import { CoreRequest } from '../../../infrastructure';

export default async function handleUploadDraft(requestUrl, fileName, fileContent) {
    const execute = async (requestUrl, fileName, body) => {
        const req = {
            requestUrl,
            headers: { 'file-name': fileName },
            body: JSON.stringify(body)
        };
        const response = await CoreRequest(req);

        return response.json();
    };

    try {
        return execute(requestUrl, fileName, fileContent);
    } catch (error) {
        console.error('Error file upload handler', error);
        throw new Error(`File upload failed: ${error}`);
    }
}
