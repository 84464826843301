import { EstimateDownloadUseCase, EstimateDownloadRawUseCase } from '../../../application';

export default class EstimateDownloadService {
    constructor(data = {}) {
        this.data = data;
    }

    async execute() {
        const request = EstimateDownloadUseCase(this.data);

        return request;
    }

    async getRawFile(Key) {
        const request = await EstimateDownloadRawUseCase(Key);

        return request;
    }
}
