import { EstimateUpdateUseCase } from '../../../application';

export default class EstimateUpdateService {
    constructor() {}

    async execute(data) {
        const request = await EstimateUpdateUseCase(data);

        return request;
    }
}
