export default function RemoveRowComponent({ handleRowRemove = () => {} }) {
    return (
        <div
            style={{
                width: 'fit-content',
                position: 'absolute',
                top: '-15px',
                left: '-10px',
                cursor: 'pointer',
                zIndex: 1
            }}
            onClick={handleRowRemove}
        >
            <i class="bi bi-x-circle-fill" style={{ fontSize: '20px', color: '#e75c5c' }}></i>
        </div>
    );
}
