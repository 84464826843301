import { SowLookupAdapter } from '../../../adapter';

export default async function SowLookup(search) {
    // prepare data
    const requestUrl =
        'https://vtaq6p2tr8.execute-api.ap-southeast-1.amazonaws.com/api/claims/estimation-module/lookup/sow';

    // handle request
    const result = await SowLookupAdapter(requestUrl, JSON.stringify(search));

    const lookup = result.map(({ category, name, type, ...others }) => ({
        label: [category, name, type].join(' '),
        category,
        name,
        type,
        ...others
    }));

    return lookup;
}
