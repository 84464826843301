export default async function handleUserAuthentication(User, AuthenticateUserStore) {
    const execute = async (props) => {
        await AuthenticateUserStore(props);
    };

    try {
        await execute(User);
    } catch (error) {
        console.error('Error update user handler', error);
        throw new Error('User Authentication Failed');
    }
}
