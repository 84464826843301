import { EstimatesGetAllUseCase } from '../../../application';

export default class EstimatesGetAllService {
    constructor() {}

    async execute(insuredId) {
        const request = await EstimatesGetAllUseCase(insuredId);

        return request;
    }
}
