import * as XLSX from 'xlsx';
import { generateTimestampUtil } from '../../../utils';

export default function handleEstimateDownload(claimDetails, claimEstimate, claimScopeOfWork, claimParts) {
    const execute = (claimDetails, claimEstimate, claimScopeOfWork, claimParts) => {
        const workbook = XLSX.utils.book_new();
        const filename = `${generateTimestampUtil()}.xls`;
        CreateSheetWithData(workbook, 'Claim Details', [claimDetails]);
        CreateSheetWithData(workbook, 'Scope of Work', claimScopeOfWork);
        CreateSheetWithData(workbook, 'Parts', claimParts);
        CreateSheetWithData(workbook, 'Estimate', [claimEstimate]);
        StartDownload(workbook, filename);
    };

    try {
        execute(claimDetails, claimEstimate, claimScopeOfWork, claimParts);
    } catch (error) {
        console.error('Error download estimate handler', error);
        throw new Error('Estimate download failed');
    }
}

function CreateSheetWithData(workbook, sheetName, data = []) {
    const sheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, sheet, sheetName);

    return workbook;
}

function StartDownload(workbook, fileName) {
    const _workbook = XLSX.write(workbook, { bookType: 'xls', type: 'array' });
    const blob = new Blob([_workbook], { type: 'application/octet-stream' });

    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);

    a.click();

    setTimeout(() => {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }, 0);
}
