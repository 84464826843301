import { useState, useEffect } from 'react';

import { DebounceUtil } from '../../../utils';

export default function DateInput(props) {
    const { id, error = undefined, label, value, handleOnChange = () => {}, ...others } = props;

    const [data, setData] = useState('');
    const debounceRef = DebounceUtil(data, 500);

    useEffect(() => {
        if (value === data) return;

        setData(() => value);
    }, [value]);

    useEffect(() => {
        handleOnChange(debounceRef, id);
    }, [debounceRef]);

    function handleChanges(change) {
        setData(() => change.target.value);
    }

    return (
        <div class="row h-100">
            <label for={id} class="col-2 col-form-label d-flex align-items-center">
                {label}
            </label>
            <div class="col-10">
                <input
                    id={id}
                    type="date"
                    class="form-control h-100"
                    value={data}
                    onChange={handleChanges}
                    {...others}
                />
            </div>
        </div>
    );
}
