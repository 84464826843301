import { InsuredsGetAllUseCase } from '../../../application';

export default class InsuredsGetAllService {
    constructor() {}

    async execute() {
        const request = await InsuredsGetAllUseCase();

        return request;
    }
}
