import { InsuredsLookupUseCase } from '../../../application';

export default class InsuredsLookupService {
    constructor() {}

    async execute(search) {
        const request = await InsuredsLookupUseCase({ search });

        return request;
    }
}
