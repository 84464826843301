import { CoreRequest } from '../../../infrastructure';

export default async function EstimateDownloadRawAdapter(requestUrl, content = {}) {
    const execute = async (requestUrl, body = '') => {
        const req = {
            requestUrl,
            body: JSON.stringify(body)
        };
        const response = await CoreRequest(req);

        return response.text();
    };

    try {
        return execute(requestUrl, content);
    } catch (error) {
        console.error('Error estimate/download handler', error);
        throw new Error(`estimate/download failed: ${error}`);
    }
}
